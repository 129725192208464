import React, { useCallback, useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Image } from '@vgn-medien-holding/vgn-fe-components';
import { AditionTagWithFallback } from '@vgn-medien-holding/vgn-fe-components';
import { getCookie } from 'cookies-next';
import { AditionInitialization } from '@components/atoms/Adition/AditionInitialization';
import { Button } from '@components/atoms/Button/Button';
import { EntityInfo } from '@components/atoms/EntityInfo/EntityInfo';
import { FallbackCard } from '@components/atoms/FallbackCard/FallbackCard';
import { Genre } from '@components/atoms/Genre/Genre';
import { Link } from '@components/atoms/Link/Link';
import { LoadingIndicator } from '@components/atoms/LoadingIndicator/LoadingIndicator';
import { Meta } from '@components/atoms/MetaTags/Meta';
import { Oewa } from '@components/atoms/Oewa/Oewa';
import { Title } from '@components/atoms/Title';
import { CinemaShowtimes } from '@components/organisms/CinemaShowtimes/CinemaShowtimes';
import { formatDate } from '@utils/dateHelpers';
import { GetBySlugDocument, Movie } from '@src/lib/graphql/generated';
import { reloadEveryPlacedAdslot } from '@lib/adition/adFarm';
import { adPositions } from '@lib/adition/adPositions';
import {
  GetCinemaDatesDocument,
  GetCinemasDocument,
  GetCinemaStatesDocument,
  GetMovieShowtimesUpcomingDocument,
  GetMoviesInCinemaDocument,
} from '@lib/graphql/generated';
import { createUrqlClient, usePaginatedQuery, useQuery } from '@lib/graphql/urql';
import { useTagManager } from '@lib/hooks/useTagManager';
import { IconArrowCollapse } from '@assets/icon-arrow-collapse';

interface RouterQuery {
  bundesland?: string;
  kino?: string;
  datum?: string;
  suche?: string;
}

export function CinemaGuide({ data }) {
  const router = useRouter();
  const query = router.query as RouterQuery;

  const [activeMovie, setActiveMovie] = useState(null);
  const [finishedInitialLoading, setFinishedInitialLoading] = useState(false);
  const [orderedMovies, setOrderedMovies] = useState([]);
  const [orderedMoviesMain, setOrderedMoviesMain] = useState([]);
  const [routerIsReady, setRouterIsReady] = useState(false);

  const [state, setState] = useState('');
  const [cinema, setCinema] = useState('');
  const [search, setSearch] = useState('');
  const [sortOrder, setSortOrder] = useState({ orderBy: '', orderDirection: 'DESC' });
  const [showMovieLoadingIndicator, setShowMovieLoadingIndicator] = useState(false);

  const [cinemaDate, setCinemaDate] = useState(new Date().toJSON().substring(0, 10));
  const availableDates = data?.dates?.find((date) => date?.date_start?.startsWith(cinemaDate));

  const [{ data: cinemasData, fetching: cinemasLoading }] = useQuery({
    query: GetCinemasDocument,
    variables: { state: state },
  });
  const cinemas = cinemasData?.cinemas?.data;

  const [{ fetching: moviesLoading, data: moviesData, hasMorePages: hasMoreMovies, fetchNextPage: fetchMoreMovies }] =
    usePaginatedQuery({
      query: GetMoviesInCinemaDocument,
      variables: {
        state: state,
        cinema: cinema,
        search: search,
        orderBy: sortOrder?.orderBy,
        orderDirection: sortOrder?.orderDirection,
        from: availableDates?.date_start,
        until: availableDates?.date_end,
        first: 15,
        page: 1,
        includeShowtimes: true,
        includeDetails: true,
      },
    });
  const moviesInCinema = moviesData?.flatMap((data) => data?.moviesInCinema?.data);

  useEffect(() => {
    if (!cinemasLoading && !cinemas?.find((value) => value?.id === cinema)) {
      setCinema('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cinemasLoading, cinemas]);

  useEffect(() => {
    reloadEveryPlacedAdslot();
    if (!moviesLoading && moviesInCinema) {
      let newActiveMovie;

      if (!finishedInitialLoading) {
        newActiveMovie = moviesInCinema.find((e) => e.id === router.query.id || e.slug === router.query.id);
        setFinishedInitialLoading(true);
      }

      if (!newActiveMovie) {
        newActiveMovie = moviesInCinema?.length > 0 ? moviesInCinema?.[0] : null;
      }

      setOrderedMoviesMain(moviesInCinema);
      setActiveMovie(newActiveMovie);
      setOrderedMovies(moviesInCinema);
    }

    setShowMovieLoadingIndicator((moviesInCinema?.length < 1 && moviesLoading) || !routerIsReady);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moviesData, router.query.id, moviesLoading, routerIsReady]);

  useEffect(() => {
    if (router.isReady) {
      setRouterIsReady(true);
      if (query.bundesland) setState(query.bundesland);
      if (query.kino) setCinema(query.kino);
      if (query.datum) setCinemaDate(query.datum);
      if (query.suche) setSearch(query.suche);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  useEffect(() => {
    if (router.isReady) {
      router.replace(
        {
          pathname: 'kino',
          query: {
            ...(state && { bundesland: state }),
            ...(cinema && { kino: cinema }),
            ...(cinemaDate && { datum: cinemaDate }),
            ...(search && { suche: search }),
          },
        },
        null,
        { shallow: true },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, cinema, cinemaDate, search]);

  useEffect(() => {
    const items = orderMoviesArray(orderedMoviesMain, activeMovie);
    setOrderedMovies(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMovie?.id]);

  function orderMoviesArray(movieItems, activeMovie) {
    if (!activeMovie?.id) return [];
    const items = [].concat(movieItems.filter((e) => e.id !== activeMovie?.id));
    items.unshift(movieItems?.find((e) => e.id === activeMovie?.id));
    return items;
  }

  const customMetaData = useCallback(() => {
    const meta = {};

    if (router.isReady) {
      const cinemaDateHuman = new Date(
        data?.dates?.find((value) => value?.date_start?.startsWith(cinemaDate))?.date_start,
      ).toLocaleString('de-AT', { year: 'numeric', month: 'numeric', day: 'numeric' });
      const stateName = data?.states?.find((value) => value['state_code'] === state)?.['state_text'];
      const cinemaName = cinemas?.find((value) => value.id === cinema)?.name;
      const description = new Set();

      description
        .add('Das aktuelle TV-MEDIA Kinoprogramm')
        .add(state && ` für ${stateName}`)
        .add(cinema && ` im ${cinemaName}`)
        .add(cinemaDate && ` am ${cinemaDateHuman}`)
        .add(': Alle Filme, alle Vorstellungen')
        .add(!cinema ? ', alle Kinos' : '')
        .add(!state ? ', alle Bundesländer' : '')
        .add('.');

      meta['meta_description'] = Array.from(description).join('');
      meta['meta_title'] = `Kinoprogramm${state && ` | ` + stateName}${cinema && ` | ` + cinemaName}${
        cinemaDate && ` | ` + cinemaDateHuman
      } | TV-MEDIA`;
    }

    return meta;
  }, [data, cinemas, state, cinema, cinemaDate, router.isReady]);

  let ad_count = 0;
  let mobile_adPos_count = 0;

  useTagManager({
    event: 'metadata',
    loginStatus: 'nicht eingeloggt',
    seitentyp: 'Kino',
    titel: 'Kino',
    aktualisierungsdatum: data?.updated_at,
    erstellungsdatum: data?.created_at,
    oewaSeitenkontingent: 'Service',
    oewaSubkontingent: 'Programmeundlokalfuehrer',
    oewaProfilingkontingent: 'TVProgramme',
    cookieAlert: getCookie('OptanonConsent'),
  });

  return (
    <>
      <Oewa pageKey={'Service'} categoryKey={'ProgrammeUndLokalfuehrer'} profileKey={'Kinoprogramme'} />
      <AditionInitialization tags={adPositions} data={data} />
      <Head>{Meta({ ...data?.metadata, ...customMetaData() })}</Head>
      <main className="dark text-white">
        <article>
          <Title
            classProps={{
              heading: 'lg:text-5xl font-herokid leading-small lg:leading-header text-center text-4xl pb-6 lg:pb-20',
            }}
            level={1}
          >
            Kinoprogramm
          </Title>
          <div className="grid gap-8 xl:grid-cols-[auto,1fr]">
            <div className="top-32 grid min-w-[300px] content-start items-start gap-y-2 md:max-w-sm xl:sticky xl:h-min xl:gap-y-4">
              <select
                className="input-tvm-classic"
                value={cinemaDate}
                onChange={(item) => setCinemaDate(item?.target.value)}
              >
                {data?.dates?.map(({ date_start, date_human }) => (
                  <option key={date_human} value={date_start?.split(' ')[0]}>
                    {date_human}
                  </option>
                ))}
              </select>
              <select className="input-tvm-classic" value={state} onChange={(item) => setState(item?.target.value)}>
                <option value="">Bundesland</option>
                {data?.states.map(({ state_code, state_text }) => (
                  <option key={state_code} value={state_code}>
                    {state_text}
                  </option>
                ))}
              </select>
              <select className="input-tvm-classic" value={cinema} onChange={(item) => setCinema(item?.target.value)}>
                <option value="">Kino</option>
                {cinemas?.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </select>
              <DebounceInput
                className="input-tvm-classic"
                element="input"
                value={search}
                minLength={2}
                debounceTimeout={200}
                onChange={(evt) => setSearch(evt.target.value)}
                type="text"
                placeholder="Suche nach Filmen"
              />
              <select
                className="input-tvm-classic min-w-[280px]"
                onChange={(item) => {
                  try {
                    setSortOrder(JSON.parse(item?.target.value));
                  } catch (err) {
                    setSortOrder({ orderBy: 'year', orderDirection: 'DESC' });
                  }
                }}
              >
                <option value='{"orderBy": "", "orderDirection": "DESC"}'>Sortieren</option>
                <option value='{"orderBy": "release_date", "orderDirection": "DESC"}'>
                  Veröffentlichung absteigend
                </option>
                <option value='{"orderBy": "release_date", "orderDirection": "ASC"}'>
                  Veröffentlichung aufsteigend
                </option>
                <option value='{"orderBy": "year", "orderDirection": "DESC"}'>Jahr absteigend</option>
                <option value='{"orderBy": "year", "orderDirection": "ASC"}'>Jahr aufsteigend</option>
                <option value='{"orderBy": "title", "orderDirection": DESC"}'>Title absteigend</option>
                <option value='{"orderBy": "title", "orderDirection": "ASC"}'>Title aufsteigend</option>
              </select>
              <AditionTagWithFallback
                tag={adPositions[33]}
                fallback={adPositions[16]}
                breakpoint={'md'}
                classProps={{ root: 'mt-6 xl:mt-0 sticky place-self-center' }}
              />
            </div>
            <div className="min-w-0 space-y-4">
              <LoadingIndicator classProps={{ root: 'min-h-[40vh]' }} visible={showMovieLoadingIndicator} />
              {!showMovieLoadingIndicator && (
                <>
                  {/* Show all movies on JS-less environments, else ordered movies */}
                  {(orderedMovies?.length < 1 && !finishedInitialLoading ? moviesInCinema : orderedMovies)?.map(
                    (movie, idx) => (
                      <React.Fragment key={movie?.id + idx}>
                        {idx > 1 && idx % 5 === 0 && ad_count < 4 && (
                          <div className="grid place-items-center">
                            <AditionTagWithFallback
                              tag={adPositions[0]}
                              tagCount={ad_count}
                              fallback={
                                adPositions[
                                  17 +
                                    (ad_count == 3 || ad_count++ % 2 != 0
                                      ? ++mobile_adPos_count
                                      : (mobile_adPos_count = 0))
                                ]
                              } //reset mobile_adPos to start at iterating again until reaching 3 -> to finish with mobile footer position
                              breakpoint={'lg'}
                              classProps={{ root: 'mt-8' }}
                            />
                          </div>
                        )}
                        <CinemaMovieCard
                          movie={movie}
                          cinema={cinema}
                          state={state}
                          cinemaDateStart={availableDates?.date_start}
                          cinemaDateEnd={availableDates?.date_end}
                          cinemaDate={cinemaDate}
                        />
                      </React.Fragment>
                    ),
                  )}
                  {hasMoreMovies && (
                    <div className="text-center">
                      <Button
                        title="Mehr anzeigen"
                        hasAction
                        isLoading={moviesLoading}
                        onAction={() => setTimeout(fetchMoreMovies)}
                        buttonStyle="secondary"
                        classProps={{ root: 'mt-6' }}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </article>
      </main>
    </>
  );
}

export interface CinemaMovieProps {
  movie: Movie;
  state?: string;
  cinema?: string;
  cinemaDateStart?: string;
  cinemaDateEnd?: string;
  cinemaDate?: string;
}

export function CinemaMovieCard({
  movie,
  cinema,
  state,
  cinemaDateStart,
  cinemaDateEnd,
  cinemaDate,
}: CinemaMovieProps) {
  const [showtimesCollapsed, setShowtimesCollapsed] = useState(false);
  const showtimesVisible = movie?.showtimesAmount > 0;

  const [{ fetching: showtimesLoading, data: showtimesData }] = useQuery({
    query: GetMovieShowtimesUpcomingDocument,
    variables: {
      id: movie?.id,
      first: 30,
      page: 1,
      cinema,
      state,
      from: cinemaDateStart,
      until: cinemaDateEnd,
    },
    pause: !showtimesCollapsed,
  });
  const showtimesUpcoming = showtimesData?.movie?.showtimesUpcoming;

  if (!movie) return;

  const params = new URLSearchParams();
  cinemaDate && params.append('datum', cinemaDate);
  cinema && params.append('kino', cinema);
  state && params.append('bundesland', state);
  const movieUrl = `/filme/${movie?.slug || movie?.id}${params.size ? '?' + params.toString() : ''}`;

  return (
    <section>
      <div className="grid gap-12 self-start bg-gray-820/70 p-4 md:grid-cols-[auto,1fr] md:p-12">
        <div className="">
          <Link href={movieUrl}>
            <div className="relative mx-auto hidden aspect-cover w-52 overflow-hidden rounded-lg md:block">
              {movie?.cover_image?.url || movie?.backdrop_image?.url ? (
                <Image
                  src={movie?.cover_image?.url || movie?.backdrop_image?.url}
                  alt={movie?.title}
                  copyright={movie?.cover_image?.copyright_text}
                  fill
                  sizes="400px"
                />
              ) : (
                <FallbackCard />
              )}
            </div>
            <div className="relative aspect-video w-full overflow-hidden rounded-md md:hidden">
              {movie?.backdrop_image?.url || movie?.cover_image?.url ? (
                <Image
                  src={movie?.backdrop_image?.url || movie?.cover_image?.url}
                  alt={movie?.title}
                  copyright={movie?.backdrop_image?.copyright_text}
                  classProps={{ root: 'object-cover' }}
                  fill
                  sizes="800px"
                />
              ) : (
                <FallbackCard />
              )}
            </div>
          </Link>
        </div>
        <div className="grid grid-rows-[1fr,auto]">
          <div>
            <Link href={movieUrl}>
              <Title level={2} classProps={{ heading: 'text-xl lg:text-2xl' }}>
                {movie?.title}
              </Title>
            </Link>
            <div className="mt-2 flex flex-wrap gap-1">
              {movie?.genres?.map((genre) => <Genre genre={genre} key={genre.id} />)}
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: movie?.summary?.slice(0, movie?.summary?.indexOf('</p>') + 4) }}
              className="mt-3 line-clamp-4 text-gray-400 lg:line-clamp-3"
            ></div>
            <Link href={movieUrl} className="text-gray-400 underline underline-offset-3">
              Mehr Infos
            </Link>
          </div>
          <div className="mt-8 grid flex-wrap gap-y-2 xl:flex xl:divide-x xl:divide-gray-650/50 [&>div]:px-10">
            {movie?.release_date && (
              <EntityInfo
                classProps={{ label: 'text-gray-400' }}
                value={formatDate(movie?.release_date?.toString())}
                label="Filmstart"
              />
            )}
            {movie?.runtime > 0 && (
              <EntityInfo
                classProps={{ label: 'text-gray-400' }}
                value={movie?.runtime?.toString()}
                unit="min"
                label="Spieldauer"
              />
            )}
            {movie?.age_rating && <EntityInfo label="Alter" value={movie.age_rating} />}
          </div>
        </div>
      </div>
      {showtimesVisible && (
        <>
          <div
            onClick={() => {
              setShowtimesCollapsed(!showtimesCollapsed);
            }}
            className="flex cursor-pointer select-none items-center justify-center p-6 text-center text-gray-650 transition-colors duration-150 hover:text-gray-400"
          >
            <IconArrowCollapse open={showtimesCollapsed} />
            <div className="px-6 text-sm font-bold uppercase tracking-2px">Vorstellungen</div>
            <IconArrowCollapse open={showtimesCollapsed} />
          </div>
          {showtimesCollapsed && (
            <>
              <LoadingIndicator visible={showtimesLoading} />
              {showtimesUpcoming?.data?.length > 0 && (
                <div className="mb-16">
                  <CinemaShowtimes showtimes={showtimesUpcoming?.data} state={state} cinema={cinema} noFilter />
                </div>
              )}
            </>
          )}
        </>
      )}
    </section>
  );
}

export const cinemaSsrLogic = async () => {
  const { ssrQuery, ssrCache } = createUrqlClient();
  const { data: statesData } = await ssrQuery({
    query: GetCinemaStatesDocument,
    variables: {},
  });
  const states = statesData?.getStates;

  const { data: cinemaData } = await ssrQuery({
    query: GetCinemaDatesDocument,
    variables: {},
  });
  const dates = cinemaData?.cinemaDates;

  await ssrQuery({
    query: GetCinemasDocument,
    variables: { state: '' },
  });

  const { data: pageData } = await ssrQuery({
    query: GetBySlugDocument,
    variables: {
      portal: 'tvmedia',
      slug: '/kino',
    },
  });
  const data = pageData?.findBySlug;

  if (!data) return { notFound: true };

  const availableDates = dates?.find((date) => date?.date_start?.startsWith(new Date().toJSON().substring(0, 10)));

  try {
    await ssrQuery({
      query: GetMoviesInCinemaDocument,
      variables: {
        state: '',
        cinema: '',
        search: '',
        orderBy: '',
        orderDirection: 'DESC',
        from: availableDates?.date_start || null,
        until: availableDates?.date_end || null,
        first: 15,
        page: 1,
        includeShowtimes: true,
        includeDetails: true,
      },
    });
  } catch (err) {
    console.error(err);
  }

  const dataSet = {
    states,
    dates,
    metadata: data !== undefined && data.__typename === 'Page' ? data?.metadata : null,
  };

  return {
    revalidate: 60 * 60 * 24 * 2, // 2 days
    props: {
      data: dataSet,
      slug: 'kino',
      urqlState: ssrCache?.extractData() ?? null,
    },
  };
};
